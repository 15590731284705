<template>
<div>
    <div class="card">
        <!-- Logo -->
        <div class="card-header pt-4 pb-4 text-center ">
            <span><img src="@/assets/images/new_logo.svg" alt="" height="52"></span>
        </div>

        <div class="card-body p-4">  
            <form action="#">
                <div class="text-center w-75 m-auto">
                    <h4 class="text-dark-50 text-center mt-0 font-weight-bold">Sign up</h4>
                </div>

                <div class="form-group">
                    <label for="fullname">Your name</label>
                    <input class="form-control" type="text" id="fullname" placeholder="Enter your name" required v-model="form.name">
                </div>

                <div class="form-group">
                    <label for="emailaddress">Email adress</label>
                    <input class="form-control" type="email" id="emailaddress" required placeholder="Enter your email" v-model="form.email">
                </div>

                <div class="form-group">
                    <label for="password">Password</label>
                    <input class="form-control" type="password" required id="password" placeholder="Enter your password" v-model="form.password">
                    <small>Minimum length is 6 charaters</small>
                </div>

                <div class="form-group mb-0 text-center">
                    <button v-if="!loading" class="btn btn-primary" type="submit" @click.prevent="submitForm()"> Sign up </button>
                    <button v-else class="btn btn-primary" type="button" disabled>
                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            </form>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12 text-center">
            <p class="text-muted">Already registered? <router-link :to="{name: 'Login'}" class="text-muted ml-1"><b> Sign in </b></router-link></p>
        </div> <!-- end col -->
    </div>
</div>
</template>


<script>
import log from '@/plugins/log'
import { to } from '@/plugins/utils'


export default {
	name: 'Signup',
    data: () => {
        return {
            form: {
                name: '',
                email: '',
                password: ''
            },
            loading: false
        }
    },
    methods: {
        async submitForm() {
            if(this.loading) return $.NotificationApp.send("Ошибка", "Please wait", "top-right", "", "error")

            if(!this.form.name) return $.NotificationApp.send("Ошибка", "Please enter your name", "top-right", "", "error")
            if(!this.form.email) return $.NotificationApp.send("Ошибка", "Please enter your email address", "top-right", "", "error")  
            if(!this.form.password) return $.NotificationApp.send("Ошибка", "Please enter your password", "top-right", "", "error")

            this.loading = true

            var [err, response] = await to(this.$http.post('/auth/signup', this.form));
			if (err) {
				this.loading = false;
				log.error('/views/empty/Login', 'methods.submitForm', '$http.request', err);
				if (err.response && err.response.data && err.response.data.error) {
					if (err.response.data.error == 'failed_validation') {
						return $.NotificationApp.send('Error', 'Check your fields', 'top-right', '', 'error');
					}
					if (err.response.data.error == 'email_already_exist') {
						return $.NotificationApp.send(
							'Error',
							'User with this email already exists',
							'top-right',
							'',
							'error',
						);
					}
				}

				return $.NotificationApp.send('Ошибка', err.message, 'top-right', '', 'error');
			}
			if (!response.data.success) {
				this.loading = false;
				log.error('/views/empty/Signup', 'methods.submitForm', '$http.response', response.data);
				return $.NotificationApp.send('Ошибка', response.data.error, 'top-right', '', 'error');
			}
			this.loading = false;

			this.$store.dispatch('auth/parseAuthResponse', [err, response]);
			window.location = '/';
        }
    }
};
</script>
